@import "./../../styles/mixins.scss";

.container {
  // display: flex;
  // flex-direction: column;
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: 1rem;
}

.parent {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 0.6fr 1fr auto;
  grid-column-gap: 24px;
  margin-top: 1.5rem;
  gap: 1rem;

  //in order to show each one on a single line while on phone mode
  @include phone-only {
    grid-template-columns: 1fr;
    grid-template-rows: auto;

    .div1,
    .div2,
    .div3,
    .div4 {
      grid-area: unset;
    }
  }
}

.div1 {
  grid-area: 1 / 1 / 2 / 2;
}
.div2 {
  grid-area: 1 / 2 / 2 / 3;
}
.div3 {
  grid-area: 2 / 1 / 4 / 3;

  height: 1rem;
}
.div4 {
  grid-area: 1 / 3 / 3 / 4;
  max-width: 100%;
}

.header {
  background-color: #ffffff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // box-shadow: #eaeef4 0px 4px 16px -16px, #eaeef1 0px 8px 32px -32px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 1.5rem;
  margin-bottom: 20px;
}
