@import "./../../styles/mixins.scss";

.container {
    display: flex;
    flex-direction: column;
    // outline: 1px dashed hotpink;
}

.title {
    margin-bottom: 1rem;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
        justify-content: center;
        width: 100%;
    
    }
    
    .inside {
    
        background-color: #FFFFFF;
        border-radius: 16px;
        padding: 32px;
}