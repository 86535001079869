.container {
  display: flex;
  flex-direction: column;
  // outline: 1px dashed hotpink;
}

.title {
  margin-bottom: 2.125rem;
}

.buttonsActions {
  color: red;

  .button {
    //   margin-right: 2rem;
    border: none;
    background-color: #ffffff;
    cursor: pointer;
  }

  :hover {
    transform: scale(0.9);
  }
}

.parent {
  display: flex;
  flex-direction: column;
}

.upperSide {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.leftSide {
  margin-right: 2rem;
}

.rightSide {
  width: 25.3rem;
}

.comments {
  margin-top: 3.37rem;
  margin-bottom: 4.625rem;
}

//   .parent{
//     display: grid;
//     //grid-template-columns: 0.5fr 0.5fr 0.8fr;
//     grid-template-rows: 0.4fr 18rem 5rem;
//     gap: 0px 0px;
//     grid-template-areas:
//         "user . docs"
//         "user . ."
//         "comms comms comms";
//   }

//   .user { grid-area: user; background-color: green; }
//   .documents { grid-area: docs; background-color: blue; }
//   .comments { grid-area: comms; background-color: purple; }
