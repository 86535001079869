@import "./../../styles/mixins.scss";
.title {
  margin-bottom: 1rem;
}

.upperSide {
  width: 25.313rem;

  @include phone-only {
    width: 100%;
  }
}

.comments {
  margin-top: 2.75rem;
}

.table {
  margin-top: 2.75rem;
}
