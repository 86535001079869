.container {
  display: flex;
  flex-direction: column;
  // outline: 1px dashed hotpink;
}

.title {
  margin-bottom: 2.125rem;
}

.upperSide {
  display: flex;
  //   border:1px dotted red;
}

.leftUpper {
  width: 25.31rem;
}

.upperDown {
  margin-top: 2rem;
}

.rightSide {
  display: flex;
  flex-direction: column;
  width: 25.31rem;
  margin-left: 2rem;
}

.rightSideDown {
  margin-top: 2rem;
  width: 25.31rem;
}

.downSide {
  margin-top: 3rem;
  max-width: 1920px;
}

.buttonsActions {
  .button {
    //   margin-right: 2rem;
    border: none;
    background-color: #ffffff;
    cursor: pointer;
  }

  :hover {
    transform: scale(0.9);
  }
}
