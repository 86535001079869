.clientContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.client {
  //   width: 100%;
  padding: 1rem;
  border-radius: 1rem;
  // cursor: pointer;
  transition: background 0.2s ease;
  border: 1px solid #f4f4f4;
  transition: border-color 0.2s ease, background 0.2s ease;


  &:hover {
    background: #f4f4f4;
  }
}

.tagContainer {
  display: flex;
  width: 100%;
  gap: 5px;
}

.selected {
  background: #f4f4f4;
  border-color: #03a5fc;
  box-shadow: 0 0 10px 0 rgba(3, 165, 252, 0.2);
}
