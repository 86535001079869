@import 'styles/mixins.scss';

.pageContainer {
    padding: 0 55px 55px 55px;

    @include phone-only {
        padding: 0 20px 20px 20px;
    }

    .componentWrapper {
        max-width: 1090px;
        margin: 0 auto;

        .headerContainer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-block: 32px;

            @include desktop {
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;
                gap: 1rem;
            }

            @include tablet-portrait {
                flex-direction: row;
                justify-content: space-between;
            }

            @include small-phone-only {
                flex-direction: column;
                align-items: center;
            }

            .titleContainer {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                // flex-shrink: 0;
                gap: 1rem;

                .versionFont {
                    font-style: italic;
                    margin-left: 1rem;
                }
            }
        }

        .detailsWrapper {
            margin-top: -15px;
            margin-bottom: 5%;
            padding: 1.5rem 2rem;
            background-color: #fff;
            border-radius: 1rem;
            box-shadow: #eaeef4 0px 4px 16px, #eaeef1 0px 8px 32px;

            .featuresArea {
                display: grid;
                gap: 0.5rem;
                padding: 1.5rem 0.5rem 0.5rem 0.5rem;

                .listOfFeatures {
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                    padding: 2% 5%;
                }
            }

            .bugsArea {
                display: grid;
                gap: 0.5rem;
                padding-left: 6%;

                .listOfBugs {
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                    padding: 2% 5%;
                }
            }
        }
    }
}