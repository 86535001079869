.container {
  display: flex;
  flex-direction: column;
  // outline: 1px dashed hotpink;
}

.title {
  margin-bottom: 2.125rem;
}

.buttonsActions {
  color: red;

  .button {
    //   margin-right: 2rem;
    border: none;
    background-color: #ffffff;
    cursor: pointer;
  }

  :hover {
    transform: scale(0.9);
  }
}

.parent {
  display: flex;
  flex-direction: column;
}

.wholeContainer {
  display: flex;
  flex-direction: row;
  // flex-wrap: wrap;
}

.leftSide {
  width: 27rem;
}

.rightSide {
  margin-left: 2rem;
  width: 27rem;

}

.container1 {
  margin-bottom: 2rem;
}
.container2 {
  margin-bottom: 2rem;
}
.container3 {
  margin-bottom: 2rem;
}

.comments {
  max-width: 1920px;
}
