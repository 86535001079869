.navBar {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  &:first-child {
    margin-top: 0;
  }
}

/* width */
.sideBarScroll::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.sideBarScroll::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
.sideBarScroll::-webkit-scrollbar-thumb {
  background: #5b80ba;
}

/* Handle on hover */
.sideBarScroll::-webkit-scrollbar-thumb:hover {
  background: #3d5f95;
}

.test::after,
.test::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  right: 0;
}

.test::before {
  top: -10px;
  border-radius: 0 0 100vw 0;
  box-shadow: 5px 5px 0 5px yellow;
}

.test::after {
  bottom: -10px;
  border-radius: 0 100vw 0 0;
  box-shadow: 5px -5px 0 5px red;
}
